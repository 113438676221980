import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import React from "react";
import { ICDPath, joinPath } from "../api/ICD10API";

export type Props = {
  open: boolean;
  parent: ICDPath;
  title?: string;
  error?: string;
  onCreate: (entries: ICDPath[]) => void;
  onClose: () => void;
};

function getEntries(body: string): string[] {
  const entries = body.split(/\r?\n/);
  return entries.map((x) => x.trim());
}

export function BulkCreateEntryDialog({ open, title, error, parent, onCreate, onClose }: Props) {
  const [body, setBody] = React.useState("");

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {title ?? `Create Children of ${parent[parent.length - 1]}`}
        <Typography style={{ opacity: 0.8 }}>One entry per line</Typography>
      </DialogTitle>

      <DialogContent>
        <TextField
          style={{ marginTop: "5px" }}
          onChange={(e) => setBody(e.target.value)}
          multiline
          minRows={8}
          maxRows={20}
          value={body}
          label="Entries"
        />
        <DialogActions>
          <Button color="error" variant="text" onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={body.trim().length === 0}
            variant="contained"
            onClick={() => onCreate(getEntries(body).map((entry) => joinPath(parent, entry)))}
          >
            Create
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
