import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React from "react";

export type Props = {
  open: boolean;
  onDecide: (discard: boolean) => void;
  onClose: () => void;
};

export function ChangesConfirmationDialog({ open, onDecide, onClose }: Props) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Save Confirmation</DialogTitle>
      <DialogContent>You have unsaved changes, would you like to discard them?</DialogContent>
      <DialogActions>
        <Button onClick={() => onDecide(false)} color="error">
          Cancel
        </Button>
        <Button onClick={() => onDecide(true)}>Discard Changes</Button>
      </DialogActions>
    </Dialog>
  );
}
