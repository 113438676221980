/* eslint-disable react-hooks/exhaustive-deps */
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { sequenceT } from "fp-ts/lib/Apply";
import { Either, either, isLeft, isRight } from "fp-ts/lib/Either";
import React from "react";
import SplitPane, { Pane } from "react-split-pane";
import { URLICD10API } from "../api/HostBasedICD10API";
import { ICDFilterResponse } from "../api/ICD10API";
import { LocationsEntity, ProvidersEntity } from "../api/location";
import { WompHealthAPI } from "../api/WompHealthAPI";
import { ChangesConfirmationDialog } from "./ChangesConfirmationDialog";
import { LocationEditor } from "./LocationEditor";
import { LocationsList } from "./LocationsList";
import { LoginError } from "./LoginError";
import { useResource } from "./ResourceWrapper";

// For some reason the error field never gets populated,
// so this is how we can tell if there is an error.
const getAuth0Error = () => {
  const url = new URLSearchParams(window.location.search);
  return url.get("error_description");
};

function App() {
  const { isLoading } = useAuth0();
  if (isLoading) {
    return <></>;
  }
  const auth0Error = getAuth0Error();
  if (auth0Error !== null) {
    return <LoginError error={auth0Error} />;
  }
  return <AppLoggedIn />;
}

function _AppLoggedIn() {
  const searchAPI = new WompHealthAPI(`${process.env.REACT_APP_LOCATION_API_HOST_URL}`);
  const icdAPI = new URLICD10API((path) => `${process.env.REACT_APP_ICD_API_HOST_URL}${path}`);

  const { isAuthenticated, isLoading } = useAuth0();

  React.useEffect(() => {
    if (isAuthenticated) {
      reloadLocations();
    }
  }, [isLoading, isAuthenticated]);

  const [reloadLocations, WithLocations] = useResource(async () =>
    Promise.all([searchAPI.locations(200, 0), icdAPI.filter("")]).then(([locations, icd]) => {
      return sequenceT(either)(locations, icd);
    })
  );

  return (
    <WithLocations
      fallback={
        <div style={{ position: "absolute", transform: "translate(-50%, -50%)", left: "50%", top: "50%" }}>
          <CircularProgress />
        </div>
      }
      error={(e) => <div>{e}</div>}
      success={function SuccessComponent(
        value: Either<string, [[LocationsEntity[], ProvidersEntity[]], ICDFilterResponse]>
      ) {
        const [location, setLocation] = React.useState<LocationsEntity | undefined>(undefined);
        const onLocationChanged = (selectedLocation: LocationsEntity) => {
          setLocation(selectedLocation);
        };

        React.useEffect(() => {
          (async () => {
            if (isRight(value) && value.right[0][0].length > 0) {
              onLocationChanged(value.right[0][0][0]);
            }
          })();
        }, []);

        const [changeConfirmationOpen, setChangeConfirmationOpen] = React.useState(false);
        const [_selectedLocation, set_selectedLocation] = React.useState<LocationsEntity | undefined>(undefined);
        const [hasChanges, setHasChanges] = React.useState(false);

        const onLocationSelected = (selectedLocation: LocationsEntity) => {
          if (hasChanges) {
            set_selectedLocation(selectedLocation);

            setChangeConfirmationOpen(true);
          } else {
            onLocationChanged(selectedLocation);
          }
        };

        const onChangeConfirmationClosed = (discard?: boolean) => {
          setChangeConfirmationOpen(false);
          if (discard === undefined || discard === false) {
            return;
          }
          if (_selectedLocation === undefined) {
            return;
          }
          onLocationChanged(_selectedLocation);
          set_selectedLocation(undefined);
        };

        const providerMap = isRight(value)
          ? Object.fromEntries(value.right[0][1].map((provider) => [provider.id, provider.Name] as const))
          : {};

        return (
          <>
            <ChangesConfirmationDialog
              open={changeConfirmationOpen}
              onDecide={onChangeConfirmationClosed}
              onClose={() => onChangeConfirmationClosed()}
            />
            <SplitPane split="vertical" defaultSize={window.innerWidth * 0.25} minSize={window.innerWidth * 0.2}>
              <Pane style={{ height: "100vh", maxHeight: "100vh" }}>
                {isLeft(value) ? (
                  <div>{value.left}</div>
                ) : (
                  <LocationsList
                    onSelectLocation={onLocationSelected}
                    selectedLocation={location}
                    locations={value.right[0][0]}
                  />
                )}
              </Pane>
              <Pane>
                <Box>
                  {location === undefined ? (
                    <Typography variant="h4">Select a location</Typography>
                  ) : (
                    <LocationEditor
                      providers={providerMap}
                      location={location}
                      icdAPI={icdAPI}
                      onHasChanged={(a) => setHasChanges(a)}
                    />
                  )}
                </Box>
              </Pane>
            </SplitPane>
          </>
        );
      }}
    />
  );
}

const AppLoggedIn = withAuthenticationRequired(_AppLoggedIn);

export default App;
