import { Typography } from "@mui/material";
import React from "react";
import { LogoutButton } from "./LogoutButton";

export type Props = {
  error: string;
};

export function LoginError({ error }: Props) {
  return (
    <div
      style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center" }}
    >
      <Typography variant="h4" gutterBottom>
        {error}
      </Typography>
      <LogoutButton label="Retry" variant="contained" />
    </div>
  );
}
