import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

export interface ICDDiff {
  action: "add" | "remove";
  id: string;
}

export type Props = {
  open: boolean;
  diff: Array<ICDDiff>;
  onSave: () => void;
  onClose: () => void;
};

export function SaveConfirmationDialog({ open, diff, onSave, onClose }: Props) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Save Confirmation</DialogTitle>
      <DialogContent>
        <List>
          {diff.map(({ action, id }, key) => (
            <ListItem disablePadding divider key={key}>
              <ListItemIcon>
                {action === "remove" ? <RemoveIcon htmlColor="red" /> : <AddIcon htmlColor="green" />}
              </ListItemIcon>
              <ListItemText primary={id}></ListItemText>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="error">
          Cancel
        </Button>
        <Button onClick={onSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
}
