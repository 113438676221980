import { List, ListItemButton, ListItemText, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { LocationsEntity } from "../api/location";
import { Inverted } from "../theme";

export type Props = {
  locations: LocationsEntity[];
  selectedLocation?: LocationsEntity;
  onSelectLocation?: (location: LocationsEntity) => void;
  disabled?: boolean;
};

export function LocationsList({ locations, selectedLocation, onSelectLocation = () => {}, disabled = false }: Props) {
  const [filter, setFilter] = React.useState("");
  return (
    <div style={{ display: "flex", flexDirection: "column", maxHeight: "100%" }}>
      <Inverted>
        <Box
          style={{
            flex: "0 0 auto",
          }}
          sx={{
            padding: "15px",
            paddingBottom: "25px",
          }}
        >
          <Typography variant="h4">Select Location</Typography>
          <TextField
            variant="standard"
            defaultValue=""
            label="Filter Locations"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
        </Box>
      </Inverted>

      <List style={{ flex: "0 1 auto" }} sx={{ overflowY: "auto" }}>
        {locations
          .filter(
            (location) =>
              location.Name.toLowerCase().includes(filter) || location.Address.toLowerCase().includes(filter)
          )
          .map((location, key) => (
            <ListItemButton
              disabled={disabled}
              divider
              key={key}
              onClick={() => onSelectLocation(location)}
              selected={selectedLocation?.id === location.id}
            >
              <ListItemText primary={location.Name} secondary={location.Address} />
            </ListItemButton>
          ))}
      </List>
    </div>
  );
}
