import axios from "axios";
import { either } from "fp-ts";
import { Either, left, right } from "fp-ts/lib/Either";
import { LocationsEntity, ProvidersEntity, WompHealthSearchRequest, WompHealthSearchResponse } from "./location";

export class WompHealthAPI {
  constructor(public readonly url: string) {}

  async search(request: WompHealthSearchRequest): Promise<Either<string, WompHealthSearchResponse>> {
    try {
      const result = await axios.post(this.url, request);
      if (result.status !== 200) {
        return left(`${result.statusText}`);
      }
      return right(result.data);
    } catch (e) {
      return left(`${e}`);
    }
  }

  async locations(top: number, skip: number) {
    const locs = await this.search({ type: "search", locations: true, brand: "pacmed", top, skip: `${skip}` });
    return either.map(
      (result: WompHealthSearchResponse) =>
        [result.locations ?? [], result.providers ?? []] as [LocationsEntity[], ProvidersEntity[]]
    )(locs);
  }
}
