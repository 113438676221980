import { useAuth0 } from "@auth0/auth0-react";
import { Button, ButtonProps } from "@mui/material";
import React from "react";

export type Props = {
  component?: typeof Button;
  label?: string;
};

export const LogoutButton = ({ component = Button, label = "Log Out", variant = "outlined" }: ButtonProps & Props) => {
  const Component = component;
  const { logout } = useAuth0();
  return (
    <Component onClick={() => logout({ returnTo: window.location.origin })} variant={variant}>
      {label}
    </Component>
  );
};
