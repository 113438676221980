/* eslint-disable react-hooks/exhaustive-deps */
import { useAuth0 } from "@auth0/auth0-react";
import { Close, Logout, Save } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  IconButton,
  LinearProgress,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { isLeft } from "fp-ts/lib/Either";
import React from "react";
import { ConditionImport, translateImport } from "../api/icd";
import {
  ICD10API,
  ICD10ConditionResponse,
  ICD10ProcedureResponse,
  ICDAddResponse,
  ICDFilterResponse,
  ICDLoadLocationResponse,
  ICDLoadTemplateResponse,
  ICDPath,
  ICDSaveTemplateResponse,
  pathID,
} from "../api/ICD10API";
import { LocationsEntity } from "../api/location";
import { runPromiseEither } from "../util";
import { usePromise } from "../util/request";
import { BulkCreateEntryDialog } from "./BulkCreateEntryDialog";
import { CreateTemplateDialog } from "./CreateTemplateDialog";
import { FilterButton } from "./FilterButton";
import { ICDView } from "./ICDView";
import { LogoutButton } from "./LogoutButton";
import { ICDDiff, SaveConfirmationDialog } from "./SaveConfirmationDialog";
import { TemplateSelector } from "./TemplateSelector";

export type Props = {
  location: LocationsEntity;
  providers: { [id: string]: string };
  icdAPI: ICD10API;
  onHasChanged: (state: boolean) => void;
};

const ICD = React.memo(ICDView);

export function LocationEditor({ location, providers: providerMap, icdAPI, onHasChanged }: Props) {
  const { value: filterValue, loading: filterLoading, request: requestFilter } = usePromise<ICDFilterResponse>();

  const { user } = useAuth0();

  const loadFilter = (filterValue: string) => {
    return requestFilter(
      runPromiseEither(() => icdAPI.filter(filterValue)),
      true
    );
  };

  React.useEffect(() => {
    loadFilter("");
  }, []);

  const [conditionDialogOpen, setConditionDialogOpen] = React.useState(false);

  const clearFilter = () => {
    setExpanded(new Set());
    setCurrentFilter("");
    return loadFilter("");
  };

  const {
    value: defaultSelections,
    loading: locationLoading,
    request: requestSelected,
  } = usePromise<ICDLoadLocationResponse>();

  const [selected, setSelected] = React.useState(new Map<string, ICDPath>());
  const [expanded, setExpanded] = React.useState(new Set<string>());
  const [picked] = React.useState<string | undefined>(undefined);

  const [currentFilter, setCurrentFilter] = React.useState<string>("");

  const onFilter = async (_filterValue: string) => {
    setCurrentFilter(_filterValue);
    if (_filterValue.trim() === "") {
      await clearFilter();
      setExpanded(new Set());
      return;
    }
    const newfilterValue = await loadFilter(_filterValue);
    if (newfilterValue === undefined) {
      return;
    }
    setExpanded((oldExpanded) => {
      const newExpanded = new Set<string>(oldExpanded);
      const addToExpand = (path: string[], response: ICD10ConditionResponse | ICD10ProcedureResponse) => {
        newExpanded.add(path[path.length - 1]);
        Object.keys(response.children ?? {}).forEach((child) => {
          addToExpand([...path, child], (response.children ?? {})[child]);
        });
      };
      addToExpand(["condition"], newfilterValue?.icd10cm ?? { children: {} });
      addToExpand(["procedure"], newfilterValue?.icd10pcs ?? { children: {} });
      console.log(newExpanded);
      return newExpanded;
    });
  };

  const reloadSelected = () => {
    return requestSelected(
      icdAPI
        .loadLocation(location.Name)
        .then((saveResult) => {
          if (isLeft(saveResult)) {
            throw saveResult.left;
          }
          return saveResult.right;
        })
        .then((alreadySelected) => {
          setSelected(new Map(alreadySelected.map((path) => [pathID(path), path])));
          return alreadySelected;
        })
    );
  };

  React.useEffect(() => {
    reloadSelected();
    loadTemplateList();
    importLocation();
  }, [location]);

  const [titleHeight, setTitleHeight] = React.useState(0);
  const titleElement = React.useCallback((node: HTMLDivElement | null) => {
    if (node !== null) {
      setTitleHeight(node.offsetHeight);
    }
  }, []);

  const [currentPath, setCurrentPath] = React.useState<ICDPath | undefined>(undefined);

  const onEntryAdded = React.useCallback((path: ICDPath) => {
    setCurrentPath(path);
    setConditionDialogOpen(true);
  }, []);

  const { loading: saveLoading, request: requestSave } = usePromise<string>();

  const onSaveProject = async () => {
    const entries = Array.from(selected.values());
    await requestSave(
      icdAPI.saveLocation(location.Name, entries).then((saveResult) => {
        if (isLeft(saveResult)) {
          throw saveResult.left;
        }
        return saveResult.right;
      })
    );
    await reloadSelected();
  };

  const { request: requestAddEntry, loading: addEntryLoading } = usePromise<ICDAddResponse>();

  const onEntryCreated = React.useCallback(
    async (entries: ICDPath[]) => {
      onConditionDialogClose();

      await Promise.all(
        entries.map((entry) =>
          requestAddEntry(
            icdAPI.add(entry).then((saveResult) => {
              if (isLeft(saveResult)) {
                throw saveResult.left;
              }
              return saveResult.right;
            })
          )
        )
      ).then(() => loadFilter(currentFilter));
    },
    [currentFilter]
  );

  const { request: requestRemoveEntry, loading: removeEntryLoading } = usePromise<ICDAddResponse>();

  const onRemoveEntry = React.useCallback(
    (entry: ICDPath) => {
      requestRemoveEntry(
        icdAPI.remove(entry).then((saveResult) => {
          if (isLeft(saveResult)) {
            throw saveResult.left;
          }
          return saveResult.right;
        })
      ).then(() => loadFilter(currentFilter));
    },
    [currentFilter]
  );

  const onConditionDialogClose = () => {
    setConditionDialogOpen(false);
    setCurrentPath(undefined);
  };

  const onDiscardChanges = () => {
    if (defaultSelections !== undefined) {
      setSelected(new Map(defaultSelections.map((path) => [pathID(path), path])));
    }
  };

  const hasProjectChanged = () => {
    const selectedPaths = Array.from(selected.keys());
    if (defaultSelections?.length !== selectedPaths.length) {
      return false;
    }
    const defaultSel = defaultSelections?.map((x) => pathID(x)) ?? [];
    const allItems = [...defaultSel, ...selectedPaths];
    for (const item of allItems) {
      if (!defaultSel.includes(item) || !selectedPaths.includes(item)) {
        return false;
      }
    }
    return true;
  };

  const hasChanged = hasProjectChanged();

  React.useEffect(() => {
    onHasChanged(!hasChanged);
  }, [hasChanged]);

  const [saveOpen, setSaveOpen] = React.useState(false);

  const getDiff = () => {
    const diff: ICDDiff[] = [];
    const defaulted = new Set((defaultSelections ?? []).map((x) => pathID(x)));

    for (const def of defaultSelections ?? []) {
      const path = pathID(def);
      if (!selected.has(path)) {
        diff.push({
          action: "remove",
          id: path,
        });
      }
    }

    for (const sel of Array.from(selected.values()).map((x) => pathID(x))) {
      if (!defaulted.has(sel)) {
        diff.push({
          action: "add",
          id: sel,
        });
      }
    }

    return diff;
  };

  const [currentDiff, setCurrentDiff] = React.useState<ICDDiff[]>([]);

  const onSave = () => {
    setSaveOpen(false);
    onSaveProject();
  };

  const { request: requestTemplates, value: templates } = usePromise<string[]>();

  const loadTemplateList = () => {
    return requestTemplates(
      icdAPI.listTemplates().then((x) => {
        if (isLeft(x)) {
          throw x.left;
        }
        return x.right;
      })
    );
  };

  const { request: requestTemplate } = usePromise<ICDLoadTemplateResponse>();
  const { request: requestSaveTemplate } = usePromise<ICDSaveTemplateResponse>();

  const loadTemplate = (name?: string) => {
    if (name === undefined) {
      applyTemplate(defaultSelections ?? []);
      return;
    }
    return requestTemplate(
      icdAPI
        .loadTemplate(name)
        .then((x) => {
          if (isLeft(x)) {
            throw x.left;
          }
          return x.right;
        })
        .then((x) => {
          applyTemplate(x);
          return x;
        })
    );
  };

  const applyTemplate = (template: ICDLoadTemplateResponse) => {
    setSelected(new Map(template.map((path) => [pathID(path), path] as const)));
  };

  const saveTemplate = (name: string) => {
    const paths = Array.from(selected.values());
    return requestSaveTemplate(
      icdAPI.saveTemplate(name, paths).then((x) => {
        if (isLeft(x)) {
          throw x.left;
        }
        return x.right;
      })
    );
  };

  const [createTemplateDialogOpen, setCreateTemplateDialogOpen] = React.useState(false);

  const onCreateTemplate = () => {
    setCreateTemplateDialogOpen(true);
  };

  const onTemplatesChanged = () => {
    return loadTemplateList();
  };

  const onCloseTemplateDialog = () => {
    setCreateTemplateDialogOpen(false);
  };

  const onCreateTemplateDialog = async (name: string) => {
    onCloseTemplateDialog();
    await saveTemplate(name);
    await onTemplatesChanged();
  };

  const deleteTemplate = (name: string) => {
    return requestSaveTemplate(
      icdAPI.saveTemplate(name, []).then((x) => {
        if (isLeft(x)) {
          throw x.left;
        }
        return x.right;
      })
    );
  };

  const onDeleteTemplate = async (name: string) => {
    await deleteTemplate(name);
    await onTemplatesChanged();
  };

  const { loading: importLoading, value: importResult, request: requestImportLocation } = usePromise<ConditionImport>();

  const importLocation = () => {
    const locationID = location.id;
    const providers = location.ProviderIds;
    return requestImportLocation(
      icdAPI.import(locationID, "pacmed", providers, 0).then((x) => {
        if (isLeft(x)) {
          throw x.left;
        }
        return translateImport(x.right, providerMap);
      })
    );
  };

  const [importedOnly, setImportedOnly] = React.useState(false);

  const onExpanded = React.useCallback((nodes: Set<string>) => {
    setExpanded((oldExpanded) => {
      const newExpanded = new Set<string>(oldExpanded);
      for (const node of Array.from(nodes)) {
        if (newExpanded.has(node)) {
          newExpanded.delete(node);
        } else {
          newExpanded.add(node);
        }
      }
      return newExpanded;
    });
  }, []);

  const onSelected = React.useCallback((nodes: ICDPath[]) => {
    setSelected((oldSelected) => {
      const newSelected = new Map(oldSelected);
      for (const node of Array.from(nodes)) {
        const id = pathID(node);
        if (newSelected.has(id)) {
          newSelected.delete(id);
        } else {
          newSelected.set(id, node);
        }
      }
      return newSelected;
    });
  }, []);

  const disableICD = filterLoading || importLoading || locationLoading || removeEntryLoading || addEntryLoading;

  return (
    <div>
      <SaveConfirmationDialog open={saveOpen} diff={currentDiff} onSave={onSave} onClose={() => setSaveOpen(false)} />
      <div ref={titleElement}>
        <AppBar position="relative" elevation={0}>
          <Toolbar>
            <div style={{ flexGrow: 1 }}></div>
            <Typography>{user?.email}</Typography>
            <LogoutButton
              component={({ children, ...props }: any) => (
                <IconButton {...props}>
                  <Logout />
                </IconButton>
              )}
            />
          </Toolbar>
        </AppBar>
        <div style={{ padding: "15px" }}>
          <Typography variant="h4">{location.Name}</Typography>
          <Typography variant="h6" sx={{ opacity: 0.6 }}>
            {location.Address}
          </Typography>
        </div>
        <Divider />
      </div>
      <Box sx={{ height: document.documentElement.clientHeight - titleHeight }}>
        <div style={{ display: "flex", flexDirection: "row", height: "100%" }}>
          <Box sx={{ padding: "15px", overflowY: "scroll", flex: "1 1 auto" }}>
            <Stack spacing={1}>
              <Stack direction="row">
                <FilterButton onFilter={onFilter} onClear={clearFilter} />
              </Stack>
              <Stack direction="row">
                <Button
                  disabled={filterLoading || hasChanged}
                  onClick={() => {
                    setCurrentDiff(getDiff());
                    setSaveOpen(true);
                  }}
                  startIcon={<Save fontSize="medium" />}
                  endIcon={saveLoading ? <CircularProgress size={20} /> : <></>}
                >
                  Save Location
                </Button>
                <Button
                  disabled={filterLoading || hasChanged}
                  onClick={onDiscardChanges}
                  color="error"
                  startIcon={<Close fontSize="medium" />}
                >
                  Undo Current Changes
                </Button>
              </Stack>
              <Divider />
              {filterValue === undefined && disableICD ? (
                <LinearProgress />
              ) : (
                <>
                  <Stack direction="row" spacing={2}>
                    <TemplateSelector
                      templates={templates ?? []}
                      onDelete={onDeleteTemplate}
                      onCreate={onCreateTemplate}
                      onSelect={loadTemplate}
                    />
                    <Stack direction="row" alignItems="center">
                      <Typography
                        sx={(a) => ({
                          ...a.typography.button,
                        })}
                      >
                        show suggested only
                      </Typography>
                      <Checkbox checked={importedOnly} onChange={(e) => setImportedOnly(e.target.checked)} />
                    </Stack>
                  </Stack>

                  <ICD
                    importedOnly={importedOnly}
                    reasons={importResult!}
                    imported={new Set(Object.keys(importResult ?? {}))}
                    onAddEntry={onEntryAdded}
                    onRemoveEntry={onRemoveEntry}
                    picked={picked}
                    procedures={filterValue?.icd10pcs ?? undefined}
                    conditions={filterValue?.icd10cm ?? undefined}
                    insurances={filterValue?.insurances ?? undefined}
                    selected={selected}
                    onSelected={onSelected}
                    expanded={expanded}
                    onExpanded={onExpanded}
                    disabled={disableICD}
                  />
                </>
              )}
            </Stack>

            {currentPath && (
              <BulkCreateEntryDialog
                parent={currentPath!}
                open={conditionDialogOpen}
                onClose={onConditionDialogClose}
                onCreate={onEntryCreated}
              />
            )}
            <CreateTemplateDialog
              open={createTemplateDialogOpen}
              onClose={onCloseTemplateDialog}
              onCreate={onCreateTemplateDialog}
            />
          </Box>
        </div>
      </Box>
    </div>
  );
}
