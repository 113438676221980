import axios from "axios";
import { Either, left, right } from "fp-ts/lib/Either";
import {
  ICD10API,
  ICDAddResponse,
  ICDFilterResponse,
  ICDImportLocationResponse,
  ICDLoadLocationResponse,
  ICDPath,
  ICDRemoveResponse,
  ICDSaveLocationResponse,
  ICDSearchResponse,
} from "./ICD10API";

export class URLICD10API implements ICD10API {
  constructor(private readonly constructHost: (path: `/api/${string}`) => string) {}

  import(
    location: string,
    brand: string,
    providers: string[],
    threshold: number
  ): Promise<Either<string, ICDImportLocationResponse>> {
    return this.makeRequest(`/api/WompIcdImport`, "POST", {
      brand,
      providers,
      location,
      threshold,
    });
  }

  listTemplates(): Promise<Either<string, string[]>> {
    return this.makeRequest(`/api/WompIcdTemplatesList`, "GET");
  }

  saveLocation(locationName: string, conditions: ICDPath[]): Promise<Either<string, ICDSaveLocationResponse>> {
    return this.makeRequest(`/api/WompIcdSave?name=${locationName}`, "POST", conditions);
  }

  loadLocation(locationName: string): Promise<Either<string, ICDLoadLocationResponse>> {
    return this.makeRequest(`/api/WompIcdLoad?name=${locationName}`, "GET");
  }

  saveTemplate(templateName: string, conditions: ICDPath[]): Promise<Either<string, ICDSaveLocationResponse>> {
    return this.makeRequest(`/api/WompIcdTemplateSave?name=${templateName}`, "POST", conditions);
  }

  loadTemplate(templateName: string): Promise<Either<string, ICDLoadLocationResponse>> {
    return this.makeRequest(`/api/WompIcdTemplateLoad?name=${templateName}`, "GET");
  }

  add(path: ICDPath): Promise<Either<string, ICDAddResponse>> {
    return this.makeRequest(`/api/WompIcdAdd?path=${path.join(",")}`, "GET");
  }

  remove(path: ICDPath): Promise<Either<string, ICDRemoveResponse>> {
    return this.makeRequest(`/api/WompIcdRemove?path=${path.join(",")}`, "GET");
  }

  search(search: string[]): Promise<Either<string, ICDSearchResponse>> {
    return this.makeRequest(`/api/WompIcdSearch`, "POST", search);
  }

  filter(filter: string): Promise<Either<string, ICDFilterResponse>> {
    return this.makeRequest(`/api/WompIcdFilter?${filter === "" ? "filter" : "search"}=${filter}`, "GET");
  }

  async makeRequest<T>(path: `/api/${string}`, method: "GET" | "POST", data?: any): Promise<Either<string, T>> {
    try {
      const result = await axios.request({
        url: this.constructHost(path),
        data,
        method,
      });
      if (result.status !== 200) {
        return left(`${result.statusText}`);
      }
      return right(result.data);
    } catch (e) {
      return left(`${e}`);
    }
  }
}

export const localhostICDAPI = new URLICD10API((apiPath) => `http://localhost:7071${apiPath}`);
