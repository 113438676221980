import { Add, IndeterminateCheckBoxOutlined, Info, Launch, Remove } from "@mui/icons-material";
import IconDown from "@mui/icons-material/KeyboardArrowDown";
import IconRight from "@mui/icons-material/KeyboardArrowRight";
import {
  Box,
  Checkbox,
  CheckboxProps,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Popper,
  Tooltip,
  Typography,
} from "@mui/material";
import { uniqueId } from "lodash";
import React, { CSSProperties } from "react";
// @ts-ignore
import sanitizeHTML from "sanitize-html";
import { ConditionImport, getICDLink } from "../api/icd";
import {
  ICD10ConditionResponse,
  ICD10ProcedureResponse,
  ICDFilterResponse,
  ICDPath,
  InsurancesResponse,
  isCustomEntry,
  joinPath,
  pathID,
} from "../api/ICD10API";

export interface ICDTreeModel {
  name: string;
  path: ICDPath;
  id: string;
  expanded: boolean;
  selectedState: EntryState;
  children: {
    [key: string]: ICDTreeModel;
  };
}

export function findParentIDs(icdValue: string, icdResponse: ICDFilterResponse): string[] {
  const search = (
    nodeName: string,
    node: ICD10ConditionResponse | ICD10ProcedureResponse,
    parents: string[]
  ): string[] | undefined => {
    const children = Object.entries(node.children ?? {});
    if (nodeName === icdValue) {
      return parents;
    }
    for (const [childName, childNode] of children) {
      const childSearch = search(childName, childNode, [...parents, nodeName]);
      if (childSearch !== undefined) {
        return childSearch;
      }
    }
    return undefined;
  };

  return (
    search("condition", icdResponse.icd10cm ?? { children: {} }, []) ??
    search("procedure", icdResponse.icd10pcs ?? { children: {} }, []) ??
    []
  );
}

export type EntryState = "checked" | "unchecked" | "suggested";

export type Props = {
  tag?: string;
  importedOnly?: boolean;
  reasons: ConditionImport;
  conditions?: ICD10ConditionResponse;
  procedures?: ICD10ProcedureResponse;
  insurances?: InsurancesResponse;
  style?: CSSProperties;
  selected: Map<string, ICDPath>;
  imported: Set<string>;
  picked?: string;
  onSelected: (selectedNodes: Array<ICDPath>) => void;
  expanded: Set<string>;
  onExpanded: (expandedNodes: Set<string>) => void;
  onAddEntry: (parentLocation: ICDPath) => void;
  onRemoveEntry: (entry: ICDPath) => void;
  disabled?: boolean;
};

export function getListItemID(entryID: string) {
  return `icd-list-item-${entryID.replaceAll(" ", "-")}` as const;
}

export type TriStateProps = {
  state: EntryState;
};

export function TriStateCheckbox({ state, ...props }: CheckboxProps & TriStateProps) {
  return (
    <Checkbox
      {...props}
      checked={state === "checked"}
      icon={state === "suggested" ? <IndeterminateCheckBoxOutlined color="primary" /> : undefined}
    />
  );
}

export function getChildren(
  entry: ICD10ConditionResponse | ICD10ProcedureResponse,
  path: ICDPath,
  shown?: Set<string>,
  selected?: Map<string, ICDPath>
): Array<[boolean, ICDPath]> {
  return Object.keys(entry.children ?? {})
    .map((child) => [child, joinPath(path, child)] as const)
    .filter(([, path]) => shown?.has(pathID(path)) ?? true)
    .flatMap(([child, childPath]) => {
      return [
        [selected?.has(pathID(childPath)), childPath] as [boolean, ICDPath],
        ...getChildren(entry.children![child], childPath, shown, selected),
      ];
    });
}

export function Matches({ matches }: { matches: { [provider: string]: string[] } }) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (e: boolean) => (event: React.MouseEvent<any>) => {
    setAnchorEl(!e ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? `popper-${uniqueId()}` : undefined;

  return (
    <>
      <Info onMouseOver={handleClick(true)} onMouseLeave={handleClick(false)} />
      <Popper id={id} open={open} anchorEl={anchorEl}>
        <Paper>
          <Box sx={{ padding: "10px" }}>
            {Object.entries(matches).map(([provider, providerMatches], key) => (
              <div key={key}>
                <div style={{ fontWeight: "500" }}>{provider}</div>
                {providerMatches.map((match, i) => (
                  <div key={i}>{match}</div>
                ))}
              </div>
            ))}
          </Box>
        </Paper>
      </Popper>
    </>
  );
}

interface ICDListItemProps {
  id?: string;
  selected: EntryState;
  isInsurance: boolean;
  expanded: boolean;
  nchildren: number;
  matches: { [provider: string]: string[] };
  label: string;
  picked?: string;
  depth: number;
  isCustom: boolean;
  onSelect: () => void;
  onExpand: () => void;
  onAddEntry: () => void;
  onRemoveEntry: () => void;
  disabled?: boolean;
}

function _ICDListItem({
  isInsurance,
  label,
  picked,
  id,
  selected,
  depth,
  isCustom,
  nchildren,
  expanded: open,
  matches,
  onExpand,
  onSelect,
  onAddEntry,
  onRemoveEntry,
  disabled,
}: ICDListItemProps) {
  const _onSelect = React.useCallback(
    (e: any) => {
      e.stopPropagation();
      onSelect();
    },
    [onSelect]
  );

  const [labelWidth, setLabelWidth] = React.useState<number | undefined>(undefined);
  const labelElement = React.useCallback((element: HTMLSpanElement | null) => {
    if (element !== null) {
      const offset = element.getBoundingClientRect().left + 300;
      const maxWidth = window.innerWidth - offset;
      setLabelWidth(Math.min(element.clientWidth + 2, maxWidth));
    }
  }, []);

  const idLoc = id === undefined ? 0 : label.lastIndexOf(id ?? "");
  const labelStripped = idLoc <= 0 || idLoc === label.length ? label : label.substring(0, idLoc - 1);
  const labelWasStripped = id !== undefined;
  const defaultOptions = {
    allowedTags: ["b", "i", "em", "strong", "a"],
    allowedAttributes: {
      a: ["href"],
    },
    allowedIframeHostnames: [],
  };
  const sanitize = (dirty: any, options: any) => ({
    __html: sanitizeHTML(dirty, { ...defaultOptions, ...options }),
  });

  return (
    <ListItem
      divider
      disabled={disabled ?? false}
      className={picked === label ? "selectedEntry" : undefined}
      sx={{ paddingLeft: depth * 2.5, height: "30px" }}
    >
      <ListItemAvatar sx={{ cursor: "pointer" }} onClickCapture={onExpand}>
        {nchildren > 0 && (open ? <IconDown /> : <IconRight />)}
      </ListItemAvatar>

      <ListItemText
        primary={
          <>
            <TriStateCheckbox state={selected} onClick={_onSelect} />
            <Typography
              component="span"
              style={{
                alignContent: "baseline",
                display: "inline-flex",
              }}
            >
              <Typography
                component="span"
                ref={labelElement}
                style={{
                  textOverflow: "ellipsis",
                  maxWidth: labelWidth === undefined ? undefined : `${labelWidth}px`,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                <span dangerouslySetInnerHTML={sanitize(labelStripped, {})}></span>
              </Typography>
              {labelWasStripped && id && (
                <>
                  &nbsp;
                  <Typography>[{id}]</Typography>
                </>
              )}
              &nbsp;
              {isCustom && <Typography style={{ opacity: 0.5, fontStyle: "italic" }}>custom</Typography>}
              {id && (
                <Tooltip title="View in ICD">
                  <Link underline="none" rel="noopener" target="_blank" href={getICDLink(id ?? "")}>
                    <Launch />
                  </Link>
                </Tooltip>
              )}
              {Object.keys(matches).length > 0 ? (
                <Typography style={{ opacity: 0.5 }}>
                  &nbsp; <Matches matches={matches} />
                </Typography>
              ) : (
                <></>
              )}
            </Typography>
          </>
        }
      />

      {!isInsurance && !isCustom && nchildren > 0 ? (
        <IconButton color="primary" component="span" size="small" onClick={onAddEntry}>
          <Add />
        </IconButton>
      ) : (
        <></>
      )}

      {!isInsurance && isCustom && (
        <IconButton color="error" component="span" size="small" onClick={onRemoveEntry}>
          <Remove />
        </IconButton>
      )}
    </ListItem>
  );
}

const ICDListItem = React.memo(_ICDListItem);

const ICDTree = ({
  selected = new Map(),
  expanded = new Set(),
  imported = new Set(),
  picked,
  onExpanded = () => {},
  onSelected = () => {},
  label,
  node,
  id,
  depth,
  open,
  onAddEntry,
  onRemoveEntry,
  path,
  shown,
  reasons,
  disabled,
}: {
  id: string;
  path: ICDPath;
  label: string;
  node: ICD10ConditionResponse | ICD10ProcedureResponse;
  depth: number;
  open: boolean;
  shown?: Set<string>;
} & Partial<Props>) => {
  const thisID = pathID(path);

  const condition = path[path.length - 1];
  const show = shown === undefined || shown.has(thisID);

  const onExpand = React.useCallback(() => onExpanded(new Set([id])), [id, onExpanded]);
  const onSelect = React.useCallback(() => {
    // Stop clicking the checkbox from opening/closing the tree
    const children = getChildren(node, path, shown, selected);
    onSelected?.([path, ...children.filter(([state]) => state === selected.has(thisID)).map(([, id]) => id)]);
  }, [node, onSelected, path, selected, shown, thisID]);

  const onAdd = React.useCallback(() => {
    onAddEntry?.(path);
  }, [onAddEntry, path]);
  const onRemove = React.useCallback(() => {
    onRemoveEntry?.(path);
  }, [onRemoveEntry, path]);

  return !show ? (
    <></>
  ) : (
    <>
      <ICDListItem
        disabled={disabled ?? false}
        id={node.id}
        depth={depth}
        expanded={open}
        isCustom={isCustomEntry(node)}
        isInsurance={path[0] === "insurances"}
        label={node.fragments?.[0] ?? label}
        selected={selected.has(thisID) ? "checked" : imported?.has(label) ? "suggested" : "unchecked"}
        picked={picked}
        matches={reasons?.[condition] ?? {}}
        nchildren={Object.keys(node.children ?? {}).length}
        key={label}
        onAddEntry={onAdd}
        onRemoveEntry={onRemove}
        onExpand={onExpand}
        onSelect={onSelect}
      />

      {open &&
        Object.entries(node.children ?? {})
          .sort((key) => (imported.has(key[0]) ? -1 : 1))
          .map(([key, value], i) => (
            <ICDTreeMemo
              disabled={disabled ?? false}
              path={joinPath(path, key)}
              imported={imported}
              key={i}
              id={key}
              node={value}
              onAddEntry={onAddEntry}
              label={key}
              depth={depth + 1}
              open={expanded.has(key)}
              {...{ expanded, onExpanded, onSelected, selected, picked, onRemoveEntry, shown, reasons }}
            />
          ))}
    </>
  );
};

const ICDTreeMemo = React.memo(ICDTree);

export function getImportedShown(
  imported: Set<string>,
  path: ICDPath,
  node: ICD10ConditionResponse | ICD10ProcedureResponse
): Set<string> {
  const id = pathID(path);
  if (imported.has(path[path.length - 1])) {
    return new Set([id]);
  } else {
    const children = Object.entries(node.children ?? {}).map(([name, childNode]) => {
      const childPath = joinPath(path, name);
      return getImportedShown(imported, childPath, childNode);
    });
    const resultSet = new Set(children.flatMap((child) => Array.from(child)));
    if (resultSet.size === 0) {
      return resultSet;
    } else {
      resultSet.add(id);
      return resultSet;
    }
  }
}

export const shownImport = (
  imported: Set<string>,
  procedures: ICD10ProcedureResponse,
  conditions: ICD10ConditionResponse
) => {
  const shownProcedures = getImportedShown(imported, ["icd10pcs"], procedures);
  const shownConditions = getImportedShown(imported, ["icd10cm"], conditions);
  shownConditions.forEach((value) => {
    shownProcedures.add(value);
  });
  return shownProcedures;
};

export function _ICDView({
  importedOnly = false,

  conditions,
  insurances,
  procedures,
  style,
  selected,
  onSelected,
  expanded,
  onExpanded,
  picked,
  onAddEntry,
  imported,
  onRemoveEntry,
  reasons,
  disabled,
}: Props) {
  React.useEffect(() => {
    if (picked !== undefined) {
      document.getElementById(getListItemID(picked))?.scrollIntoView();
    }
  }, [picked]);

  const shown = importedOnly && procedures && conditions ? shownImport(imported, procedures, conditions) : undefined;
  return (
    <Box style={style}>
      <List disablePadding>
        {insurances && (
          <ICDTreeMemo
            disabled={disabled ?? false}
            label="Insurances"
            id="insurances"
            path={["insurances"]}
            node={insurances}
            imported={imported}
            depth={0}
            open={expanded.has("insurances")}
            onAddEntry={onAddEntry}
            {...{ expanded, onExpanded, onSelected, selected, picked, onRemoveEntry, shown, reasons }}
          />
        )}
        {procedures && (
          <ICDTreeMemo
            disabled={disabled ?? false}
            label="Procedures"
            id="procedure"
            path={["icd10pcs"]}
            node={procedures}
            imported={imported}
            depth={0}
            open={expanded.has("procedure")}
            onAddEntry={onAddEntry}
            {...{ expanded, onExpanded, onSelected, selected, picked, onRemoveEntry, shown, reasons }}
          />
        )}
        {conditions && (
          <ICDTreeMemo
            disabled={disabled ?? false}
            label="Conditions"
            id="condition"
            path={["icd10cm"]}
            node={conditions}
            imported={imported}
            depth={0}
            open={expanded.has("condition")}
            onAddEntry={onAddEntry}
            {...{ expanded, onExpanded, onSelected, selected, picked, onRemoveEntry, shown, reasons }}
          />
        )}
      </List>
    </Box>
  );
}

export const ICDView = React.memo(_ICDView);
