import { Either } from "fp-ts/lib/Either";

export interface ICD10ProcedureResponse {
  children: { [procedureName: string]: ICD10ProcedureResponse } | null;
  isProvidence?: boolean;
  id?: string;
  fragments?: string[];
}

export interface ICD10ConditionResponse {
  children: { [conditionName: string]: ICD10ConditionResponse } | null;
  isProvidence?: boolean;
  id?: string;
  fragments?: string[];
}

export type ICDEntry = ICD10ProcedureResponse | ICD10ConditionResponse;

export type _ICDTree<T> = {
  id: string;
  path: ICDPath;
  name: string;
  type: T;
  metadata: {
    isProvidence: boolean;
    id: string;
  };
  children: { [x: string]: _ICDTree<T> };
};

export type ICDTree = _ICDTree<"procedure"> | _ICDTree<"condition">;

export function pathID(icdPath: ICDPath) {
  return icdPath.join(">");
}

export function entryToTree(
  entry: ICD10ProcedureResponse,
  name: string,
  type: "procedure",
  parentId?: ICDPath
): _ICDTree<"procedure">;
export function entryToTree(
  entry: ICD10ConditionResponse,
  name: string,
  type: "condition",
  parentId?: ICDPath
): _ICDTree<"condition">;
export function entryToTree(
  entry: ICDEntry,
  name: string,
  type: "condition" | "procedure",
  parentPath: ICDPath = type === "condition" ? ["icd10cm"] : ["icd10pcs"]
): ICDTree {
  const path = joinPath(parentPath, name);
  const id = pathID(path);
  return {
    id,
    name,
    path,
    type,
    metadata: {
      isProvidence: entry.isProvidence ?? false,
      id: entry.id ?? "",
    },
    children: Object.fromEntries(
      Object.entries(entry.children ?? {}).map(([key, value]) => [
        key,
        entryToTree(value, key, type as any, path) as any,
      ])
    ),
  };
}

export function isCustomEntry(entry: ICD10ProcedureResponse | ICD10ConditionResponse): boolean {
  return entry.isProvidence ?? false;
}

export interface InsurancesResponse {
  children: {
    [insuranceName: string]: InsurancesResponse;
  } | null;
}

export interface ICDFilterResponse {
  icd10pcs: ICD10ProcedureResponse | null;
  icd10cm: ICD10ConditionResponse | null;
  insurances: InsurancesResponse | null;
}

export interface ICDSearchResponse {
  [conditionName: string]: {
    score: number;
    fragments: string[];
  };
}

export interface ICDAddResponse {}

export interface ICDRemoveResponse {}

export type ICDSaveLocationResponse = string;
export interface ICDLoadLocationResponse extends Array<ICDPath> {}

export type ICDSaveTemplateResponse = string;
export interface ICDLoadTemplateResponse extends Array<ICDPath> {}

export interface ICDImportLocationResponse {
  [provider: string]: {
    [condition: string]: string[];
  };
}

export type ICDPath = { 0: "icd10cm" | "icd10pcs" | "insurances" } & Array<string>;

export function joinPath(path: ICDPath, ...segments: string[]): ICDPath {
  return [path[0], ...path.slice(1), ...segments];
}

export interface ICD10API {
  search(search: string[]): Promise<Either<string, ICDSearchResponse>>;
  filter(filter: string): Promise<Either<string, ICDFilterResponse>>;
  add(path: ICDPath): Promise<Either<string, ICDAddResponse>>;
  remove(path: ICDPath): Promise<Either<string, ICDRemoveResponse>>;
  saveLocation(locationName: string, entries: Array<ICDPath>): Promise<Either<string, ICDSaveLocationResponse>>;
  loadLocation(locationName: string): Promise<Either<string, ICDLoadLocationResponse>>;
  saveTemplate(templateName: string, entries: Array<ICDPath>): Promise<Either<string, ICDSaveTemplateResponse>>;
  loadTemplate(templateName: string): Promise<Either<string, ICDLoadTemplateResponse>>;
  listTemplates(): Promise<Either<string, string[]>>;
  import(
    locationID: string,
    brand: string,
    providerIDs: string[],
    threshold: number
  ): Promise<Either<string, ICDImportLocationResponse>>;
}
