import axios from "axios";
import React from "react";

export function useRequest<T>(): [
  boolean,
  string | undefined,
  T | undefined,
  (url: string, method: "GET" | "POST", body?: any) => Promise<void>
] {
  const [state, setState] = React.useState<[boolean, string | undefined, T | undefined]>([
    false,
    undefined as string | undefined,
    undefined as T | undefined,
  ]);

  return [
    ...state,
    async (url: string, method: "GET" | "POST", body?: any) => {
      try {
        const result = await axios.request({
          url,
          data: body,
          method,
        });
        if (result.status !== 200) {
          setState([true, result.statusText, undefined]);
        } else {
          setState([true, undefined, result.data]);
        }
      } catch (e) {
        setState([true, `${e}`, undefined]);
      }
    },
  ];
}

export interface UsePromise<T> {
  loading: boolean;
  done: boolean;
  error: string | undefined;
  value: T | undefined;
  request: (p: Promise<T>, keepOld?: boolean) => Promise<T | undefined>;
}

export function usePromise<T>(): UsePromise<T> {
  const [state, setState] = React.useState<[boolean, boolean, string | undefined, T | undefined]>([
    false,
    false,
    undefined as string | undefined,
    undefined as T | undefined,
  ]);

  return {
    loading: state[0],
    done: state[1],
    error: state[2],
    value: state[3],
    request: async (p: Promise<T>, keepOld: boolean = false) => {
      try {
        if (!keepOld) {
          setState([true, false, undefined, undefined]);
        } else {
          setState((oldState) => [true, false, oldState[2], oldState[3]]);
        }
        const result = await p;
        setState([false, true, undefined, result]);
        return result;
      } catch (e) {
        setState([false, true, `${e}`, undefined]);
      }
    },
  };
}
