import React from "react";

export type Props<T> = {
  fallback: JSX.Element;
  error: (error: any) => JSX.Element;
  success: (value: T) => JSX.Element;
};

export function useResource<T, Args extends any[]>(
  getResource: (...args: Args) => Promise<T>
): [(...args: Args) => Promise<void>, React.ComponentType<Props<T>>] {
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState<any>(undefined);
  const [result, setResult] = React.useState<T | undefined>(undefined);

  const reloadResource = async (...args: Args) => {
    setLoading(true);
    setResult(undefined);

    try {
      try {
        const x = await getResource(...args);
        setError(undefined);
        setResult(x);
      } catch (e) {
        setError(e);
        setResult(undefined);
      }
    } finally {
      setLoading(false);
    }
  };

  return [
    reloadResource,
    function ResourceWrapper({ fallback: FallbackComponent, success: SuccessComponent, error: ErrorComponent }) {
      return (
        <>{loading ? FallbackComponent : error !== undefined ? ErrorComponent(error) : SuccessComponent(result!)}</>
      );
    },
  ];
}
