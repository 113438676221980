import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from "@mui/material";
import React from "react";

export type Props = {
  open: boolean;
  onClose: () => void;
  onCreate: (templateName: string) => void;
};

export function CreateTemplateDialog({ open, onClose, onCreate }: Props) {
  const [name, setName] = React.useState("");

  const _onClose = () => {
    setName("");
    onClose();
  };

  const _onCreate = () => {
    setName("");
    onCreate(name);
  };

  return (
    <Dialog open={open} onClose={() => onClose()}>
      <DialogTitle>Create New Template</DialogTitle>
      <DialogContent>
        <Stack spacing={1} direction="column">
          <br />
          <TextField label="Template Name" value={name} onChange={(e) => setName(e.target.value)} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={_onClose} color="error">
          Close
        </Button>
        <Button disabled={name.length === 0} onClick={_onCreate}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}
