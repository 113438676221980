import { ICD10ConditionResponse, ICD10ProcedureResponse, ICDImportLocationResponse } from "./ICD10API";

export function getProcedures(procedureResponse: ICD10ProcedureResponse) {
  return Object.keys(procedureResponse.children ?? {});
}

export function getConditions(conditionResponse: ICD10ConditionResponse) {
  return Object.keys(conditionResponse.children ?? {});
}

export interface ConditionImport {
  [condition: string]: { [provider: string]: string[] };
}

export function translateImport(
  importResponse: ICDImportLocationResponse,
  providerMap: { [id: string]: string }
): ConditionImport {
  const result: ConditionImport = {};
  Object.entries(importResponse).forEach(([provider, conditions]) => {
    Object.entries(conditions).forEach(([condition, keywords]) => {
      const providerMapped = providerMap[provider];
      result[condition] = result[condition] ?? {};
      result[condition][providerMapped] = result[condition][providerMapped] ?? [];
      result[condition][providerMapped].push(...keywords);
    });
  });
  return result;
}

export function getICDLink(id: string) {
  return `https://icd.who.int/browse10/2019/en#/${id}`;
}
