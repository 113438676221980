import { Close } from "@mui/icons-material";
import { FormControl, IconButton, ListItemText, MenuItem, Select, Stack, Typography } from "@mui/material";
import React from "react";

export type Props = {
  templates: Array<string>;
  onSelect: (name?: string) => void;
  onDelete: (name: string) => void;
  onCreate: () => void;
};

export function TemplateSelector({ templates, onSelect, onCreate, onDelete }: Props) {
  const [value, setValue] = React.useState<number | string | undefined>(0);
  const NONE_VALUE = 0;
  const CREATE_VALUE = 1;
  return (
    <form>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography
          sx={(a) => ({
            ...a.typography.button,
          })}
        >
          Templates
        </Typography>
        <FormControl>
          <Select
            defaultValue={NONE_VALUE}
            size="small"
            value={value}
            renderValue={(value) =>
              value === NONE_VALUE ? (
                <em style={{ opacity: 0.6 }}>None</em>
              ) : value === CREATE_VALUE ? (
                "Create New Template"
              ) : (
                value
              )
            }
            onChange={(e) => {
              if (e.target.value === CREATE_VALUE) {
                e.preventDefault();
                onCreate();
              } else {
                setValue(e.target.value);
                onSelect(e.target.value === NONE_VALUE ? undefined : (e.target.value as string | undefined));
              }
            }}
          >
            <MenuItem selected value={NONE_VALUE}>
              <em style={{ opacity: 0.6 }}>None</em>
            </MenuItem>
            {templates.map((template, key) => (
              <MenuItem selected={value === template} value={template} key={key}>
                <ListItemText primary={template}></ListItemText>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    onDelete(template);
                  }}
                >
                  <Close color="error" />
                </IconButton>
              </MenuItem>
            ))}
            <MenuItem value={CREATE_VALUE}>
              <span style={{ opacity: 0.6 }}>Create New Template</span>
            </MenuItem>
          </Select>
        </FormControl>
      </Stack>
    </form>
  );
}
