import { Auth0Provider } from "@auth0/auth0-react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { getTheme } from "./theme";

// if (process.env.NODE_ENV !== "production") {
//   const { whyDidYouUpdate } = require("why-did-you-update");
//   whyDidYouUpdate(React);
// }

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain="dev-womp-mobile.us.auth0.com"
      clientId="jHqwEpWKvN37NeRTpJVAh5SufD01fBnC"
      redirectUri={window.location.origin}
      useRefreshTokens
      cacheLocation="localstorage"
    >
      <ThemeProvider theme={getTheme("light")}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
