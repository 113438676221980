import { Box, createTheme, PaletteMode, ThemeProvider } from "@mui/material";

export const getTheme = (mode: PaletteMode) =>
  createTheme({
    palette: {
      mode,
      ...(mode === "light"
        ? {
            primary: {
              main: "#5a92cf",
            },
            secondary: {
              main: "#CF295A",
            },
            text: {},
          }
        : {
            primary: {
              main: "#ffffff",
            },
            secondary: {
              main: "#CF295A",
            },
            background: {
              default: "#3572a9",
            },
            text: {
              primary: "#ffffff",
            },
          }),
    },
  });

export function Inverted({ children }: any) {
  return (
    <ThemeProvider theme={getTheme("dark")}>
      <Box
        sx={(theme) => ({
          color: "white",
          bgcolor: "#5a92cf",
        })}
      >
        {children}
      </Box>
    </ThemeProvider>
  );
}
