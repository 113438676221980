import { Either, isLeft } from "fp-ts/lib/Either";

export function runPromiseEither<L, R>(p: () => Promise<Either<L, R>>) {
  return p().then((result) => {
    if (isLeft(result)) {
      throw result.left;
    }
    return result.right;
  });
}

export function isProduction() {
  const url = window.location;
  return url.hostname.includes("locationeditor.wompmobile.com");
}
