import Close from "@mui/icons-material/Close";
import { Button, IconButton, InputAdornment, Stack, TextField } from "@mui/material";
import React from "react";

export function FilterButton({
  onFilter,
  onChange = () => {},
  value,
  onClear,
}: {
  onFilter: (filter: string) => void;
  onChange?: (filter: string) => void;
  onClear: () => void;
  value?: string;
}) {
  const [filter, setFilter] = React.useState("");
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onFilter(value ?? filter);
      }}
    >
      <Stack direction="row" spacing={1}>
        <TextField
          size="small"
          value={value ?? filter}
          onChange={(e) => {
            setFilter(e.target.value);
            onChange(e.target.value);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  disabled={(value ?? filter).length === 0}
                  onClick={() => {
                    setFilter("");
                    onClear();
                  }}
                >
                  <Close fontSize="small" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant="outlined"
          onSubmit={(e) => {
            e.preventDefault();
            onFilter(value ?? filter);
          }}
          onClick={() => onFilter(value ?? filter)}
        >
          Filter
        </Button>
      </Stack>
    </form>
  );
}
